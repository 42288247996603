import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from 'components/Layout';
import Seo from 'components/SEO';
import { PrismicRichText } from '@prismicio/react';
import Header from 'components/Header';
import headerPro from 'images/headerPro.svg';
import { Container, Button, Buttons } from '../ui';
import { MdChevronLeft, MdInsertDriveFile } from 'react-icons/md';
import { asText } from '@prismicio/richtext';

const Attachments = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${({ theme }) => theme.spacing(-2)};
  margin-top: ${({ theme }) => theme.spacing(-1)};
`;

const Attachment = styled.a`
  margin-top: ${({ theme }) => theme.spacing()};
  margin-left: ${({ theme }) => theme.spacing(2)};
  display: flex;
  align-items: center;
  border-radius: 100px;
  text-decoration: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
  color: ${({ theme }) => theme.primary};
  border: 1px solid ${({ theme }) => theme.primary};
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(1)};

  svg {
    margin-right: ${({ theme }) => theme.spacing(0.5)};
    font-size: 1.25rem;
  }

  &:after {
    content: '' !important;
  }
`;

const StaticPage = ({ data, pageContext }) => {
  const { app } = pageContext;
  const { title, content, attachments } = data?.prismicStaticPage?.data;

  return (
    <Layout variant={app}>
      <Seo title={title && asText(title)} />
      <Header
        title={<PrismicRichText field={title} />}
        icon={headerPro}
        variant="withLogo"
        app={app}
      />
      <Container size="narrow">
        <Buttons alignment="left">
          <Button variant="primary" as={Link} to={app === 'pro' ? '/pro' : '/'}>
            <MdChevronLeft />
            Retour à l’accueil
          </Button>
        </Buttons>
        <PrismicRichText field={content?.richText} />
        {attachments.length > 0 && (
          <Attachments>
            {attachments.map(
              ({ attachment }, index) =>
                attachment?.url && (
                  <Attachment
                    key={index}
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferer"
                    download
                  >
                    <MdInsertDriveFile />

                    {attachment?.raw?.name}
                  </Attachment>
                ),
            )}
          </Attachments>
        )}
      </Container>
    </Layout>
  );
};

export default StaticPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    prismicStaticPage(uid: { eq: $slug }, lang: { eq: "fr-fr" }) {
      data {
        title {
          text
          richText
        }
        content {
          richText
        }
        attachments {
          attachment {
            link_type
            url
            raw
          }
        }
      }
    }
  }
`;
